import router from "next/router";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { DesktopContext } from "../../contexts/DesktopContextProvider/DesktopContextProvider";
import CreateAPostModal from "../../components/primaryrender/editor/CreateAPostModal/CreateAPostModal";
import SchedulePostModal from "../../components/primaryrender/editor/SchedulePostModal/SchedulePostModal";
import {
  createFacebookPost,
  createGmbPost,
  deleteFacebookPost,
  deleteGooglePost,
  editFacebookPost,
  editGooglePost,
  generateSocialMediaPost,
  getS3File,
  getRewritePost,
  isVideo,
  createInstagramPost,
  getPhotoDescriptions,
} from "../../components/primaryrender/editor/utils";
import { editPostToast, postToast } from "../utils";
import ActionModal from "../../components/common/ActionModal/ActionModal";
import { createFile } from "../../components/primaryrender/editor/BeforeAndAfterModal/utils";
import { ActionTiming, Features } from "../useFeatureGating/utils";
import { useFeatureGating } from "../useFeatureGating/useFeatureGating";
import { useSyncSocials } from "../useSyncSocials/useSyncSocials";
import useChurnModal from "../useChurnModal/useChurnModal";
import UploadProgressBar from "../../components/primaryrender/editor/UploadPhotoModal/UploadProgressBar";
import {
  createFacebookGroupPost,
  editFacebookGroupPost,
  generateGroupIntroPost,
} from "../../components/primaryrender/editor/FacebookGroups/api";
import {
  checkIfDigitalAssetAttached,
  validateSocialContent,
} from "./validateSocialContent";

export function useNewPostModal(pullPosts = () => Promise.resolve()) {
  const {
    profileInfo,
    basicInfo,
    isGmbAuthenticated,
    isFacebookAuthenticated,
    proHasChurned,
    allDigitalMedia,
    isInstagramAuthenticated,
    featureUsage,
    socialContentError,
    setSocialContentError,
    attachedPhotos,
    setAttachedPhotos,
  } = useContext(DesktopContext);

  const { renderFeatureGateModal, updateFeatureUsage, checkFeatureUsage } =
    useFeatureGating();

  const slug = profileInfo?.slug;

  const [editingPost, setEditingPost] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [attachingPhoto, setAttachingPhoto] = useState(false);
  const [schedulePostModalOpen, setSchedulePostModalOpen] = useState(false);
  const [replaceMessageModalOpen, setReplaceMessageModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const { renderChurnModal, shouldOpenChurnModal, setOpenChurnModal } =
    useChurnModal();

  const [generatingPost, setGeneratingPost] = useState(false);

  const currentPlan = profileInfo?.current_plan;

  const [rewritePostErrorMessage, setRewritePostErrorMessage] = useState("");
  const [digitalAssetsCount, setDigitalAssetsCount] = useState(0);
  const [newMediaLoading, setNewMediaLoading] = useState(false);
  const [slideUpOpen, setSlideUpOpen] = useState(false);

  const [postProgress, setPostProgress] = useState(0);

  const {
    toggleGoogleStatus,
    toggleFacebookStatus,
    editPostToggleFacebookStatus,
    setEditPostToggleFacebookStatus,
    editPostToggleGoogleStatus,
    setEditPostToggleGoogleStatus,
    editPostToggleInstagramStatus,
    toggleInstagramStatus,
    renderSyncModals,
    renderSyncPills,
    renderSyncEditPostPills,
  } = useSyncSocials("posts");

  const { default_review_link } = basicInfo || {};

  useEffect(() => {
    const media = allDigitalMedia?.filter((media: any) => !media.archived);
    setDigitalAssetsCount(media?.length);
  }, [allDigitalMedia]);

  useEffect(() => {
    setRewritePostErrorMessage("");
  }, [newMessage]);

  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        const maxProgress = 100;
        setPostProgress((prevProgress) => {
          if (prevProgress < maxProgress) {
            return prevProgress + 1;
          }
          return prevProgress;
        });
      }, 250);

      return () => clearInterval(interval);
    }
  }, [loading]);

  useEffect(() => {
    if (
      (!newMessage?.length && !attachedPhotos?.length) ||
      (!toggleFacebookStatus && !toggleGoogleStatus && !toggleInstagramStatus)
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [
    newMessage,
    attachedPhotos,
    toggleFacebookStatus,
    toggleGoogleStatus,
    toggleInstagramStatus,
  ]);

  useEffect(() => {
    if (router.pathname.includes("/settings/refer-a-friend")) {
      const customerId = profileInfo?.customer_pk;
      const environment = process.env.NEXT_PUBLIC_ENVIRONMENT;
      const referralLink = `${
        environment === "development" && "dev."
      }app.toplinepro.com/signup?id=${customerId}`;

      const body = `Topline Pro built a website for my business within a day and they’ve helped me put my company growth on autopilot. They’ll build a free website draft for you if you sign up using my link below.\n\n${referralLink}`;
      setNewMessage(body);
    }
    if (router.pathname.includes("/social") && router.query?.generatePost) {
      handleGeneratePost();
    }
  }, []);

  useEffect(() => {
    setSocialContentError(null);
  }, [
    attachedPhotos,
    newMessage,
    toggleFacebookStatus,
    toggleGoogleStatus,
    toggleInstagramStatus,
  ]);

  async function handleImagesSelected(images: any) {
    setSocialContentError(null);
    setSlideUpOpen(false);

    setAttachingPhoto(true);
    const photosArray = [];

    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      if (image.data === "loading") {
        return;
      }

      const file = await createFile(image?.data, image?.type);
      const signedRequest = await getS3File(file);
      console.log(signedRequest);

      const photoUrl = `https://landing-page-app-hero-images.s3.amazonaws.com/media/${signedRequest}`;
      photosArray.push({
        photo: photoUrl,
        data: photoUrl,
        url: photoUrl,
        type: image.type,
        name: image.name,
      });
    }

    setAttachedPhotos([...attachedPhotos, ...photosArray]);
    setAttachingPhoto(false);
    setRewritePostErrorMessage("");
  }

  async function postToGoogle(mediaFiles: any[]) {
    let gmbPostResponse = "";

    if (isGmbAuthenticated && toggleGoogleStatus) {
      if (mediaFiles[0]?.type !== "video") {
        const photos = mediaFiles?.length > 0 ? mediaFiles : [];
        gmbPostResponse = await createGmbPost(slug, newMessage, photos);
      }
    }

    return gmbPostResponse;
  }

  async function postToFacebook(mediaFiles: any[]) {
    let fbPostResponse = "";

    if (isFacebookAuthenticated && toggleFacebookStatus) {
      fbPostResponse = await createFacebookPost(slug, newMessage, mediaFiles);
    }

    return fbPostResponse;
  }

  async function postToInstagram(caption: string, mediaFiles: any) {
    let igPostResponse = "";

    if (isInstagramAuthenticated && toggleInstagramStatus) {
      igPostResponse = await createInstagramPost(caption, mediaFiles);
    }

    return igPostResponse;
  }

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setRewritePostErrorMessage("");
    setNewMessage(event.target.value);
  };

  function removeSocialFooterFromMessage(message: string) {
    if (basicInfo?.is_social_footer_active) {
      const footer = basicInfo?.social_post_footer.replaceAll("\r", "").trim();
      const messageWithoutFooter = message.replace(footer, "").trim();
      return messageWithoutFooter;
    }
    return message;
  }

  function addSocialFooterToMessage(message: string) {
    const footer = basicInfo?.social_post_footer;

    if (basicInfo?.is_social_footer_active && !message.includes(footer)) {
      return `${message}\n\n${footer}`;
    }
    return message;
  }

  async function handleEditFacebookPost(
    postToEditIds: any,
    attachedMedia: any[]
  ) {
    let fbResponse;

    if (editPostToggleFacebookStatus && postToEditIds.facebook) {
      fbResponse = await editFacebookPost(
        slug,
        postToEditIds,
        newMessage,
        attachedMedia
      );
    }

    if (!editPostToggleFacebookStatus && postToEditIds.facebook) {
      fbResponse = await deleteFacebookPost(slug, postToEditIds);
    }

    if (editPostToggleFacebookStatus && !postToEditIds.facebook) {
      fbResponse = await createFacebookPost(slug, newMessage, attachedMedia);
    }

    return fbResponse;
  }

  async function handleEditGooglePost(postToEditIds: any, mediaFiles: any[]) {
    let googleResponse;

    if (editPostToggleGoogleStatus && postToEditIds.google) {
      googleResponse = await editGooglePost(
        slug,
        postToEditIds,
        newMessage,
        mediaFiles
      );
    }

    if (!editPostToggleGoogleStatus && postToEditIds.google) {
      googleResponse = await deleteGooglePost(slug, postToEditIds);
    }

    if (editPostToggleGoogleStatus && !postToEditIds.google) {
      googleResponse = await createGmbPost(slug, newMessage, mediaFiles);
    }

    return googleResponse;
  }

  async function handleGeneratePost(
    photosArray?: any[] | null,
    isGroupPost: boolean = false,
    isIntroPost: boolean = false
  ) {
    const hasHitLimit = checkFeatureUsage(
      featureUsage,
      Features.SOCIAL_GENERATE_POST,
      ActionTiming.Before
    );
    if (hasHitLimit) {
      return;
    }

    setGeneratingPost(true);
    setNewMessage("");

    let attachedPhotosArray = photosArray ? photosArray : attachedPhotos;
    const photosNeedDescription = attachedPhotosArray.some(
      (photo) => !photo?.gpt_description
    );

    const isDigitalAssetAttached = checkIfDigitalAssetAttached(attachedPhotos);
    const isVideoAttached = attachedPhotosArray.some(isVideo);
    if (photosNeedDescription && !isVideoAttached && !isDigitalAssetAttached) {
      attachedPhotosArray = await getPhotoDescriptions(
        slug,
        attachedPhotosArray
      );
    }

    try {
      let postContent;
      if (isGroupPost) {
        if (isIntroPost) {
          postContent = await generateGroupIntroPost();
          const coverPhotoGallery = basicInfo?.hero_gallery_photos;

          if (coverPhotoGallery?.length > 0) {
            const videos = coverPhotoGallery.filter((asset) => {
              return isVideo(asset);
            });
            const photos = coverPhotoGallery.filter((asset) => {
              return !isVideo(asset);
            });

            // if only videos present, attach the first video only
            if (videos.length > 0 && photos.length === 0) {
              setAttachedPhotos([videos[0]]);
            }
            // if photos and/or videos present, attach up to the first 5 photos
            // which is the max num of assets allowed in the Cover Photo gallery
            if (photos.length > 0) {
              setAttachedPhotos(photos.slice(0, 5));
            }
          }
        } else {
          postContent = await generateSocialMediaPost(
            slug,
            attachedPhotosArray
          );
        }
      } else if (
        attachedPhotos &&
        attachedPhotos[0] &&
        attachedPhotos[0].type == "Service Highlights"
      ) {
        postContent = await generateSocialMediaPost(slug, attachedPhotos);
      } else {
        postContent = await generateSocialMediaPost(slug, attachedPhotosArray);
        postContent = addSocialFooterToMessage(postContent);
      }
      setNewMessage(postContent);
    } catch (e) {
      console.log("error generating post", e);
    }

    updateFeatureUsage(slug, Features.SOCIAL_GENERATE_POST);

    setGeneratingPost(false);
  }

  async function handleRewriteForMe() {
    const hasHitLimit = checkFeatureUsage(
      featureUsage,
      Features.SOCIAL_REWRITE_POST,
      ActionTiming.Before
    );
    if (hasHitLimit) {
      return;
    }

    const formattedMessage = removeSocialFooterFromMessage(newMessage);

    if (formattedMessage.length == 0) {
      setRewritePostErrorMessage("Please write a post first.");
      return;
    }
    setGeneratingPost(true);

    try {
      setRewritePostErrorMessage("");
      if (profileInfo?.slug) {
        const newMessageResponse = await getRewritePost(
          profileInfo?.slug,
          formattedMessage
        );
        if (newMessageResponse?.status === "success") {
          const { message } = newMessageResponse;
          const messageWithFooter = addSocialFooterToMessage(message);
          setNewMessage(messageWithFooter);
        }
      }
    } catch (e) {
      console.log("error rewriting post", e);
    }

    updateFeatureUsage(slug, Features.SOCIAL_REWRITE_POST);

    setGeneratingPost(false);
  }

  function checkSocialContentError(isGroupPost: boolean = false) {
    const googleToggle = getToggleGoogleStatus();
    const facebookToggle = getToggleFacebookStatus();
    const instagramToggle = getToggleInstagramStatus();

    const error = validateSocialContent(
      isGroupPost,
      facebookToggle,
      googleToggle,
      instagramToggle,
      newMessage,
      attachedPhotos
    );

    if (error) {
      setSocialContentError(error);
      return true;
    }

    return false;
  }

  function getToggleFacebookStatus() {
    if (editingPost) {
      return editPostToggleFacebookStatus;
    } else {
      return toggleFacebookStatus;
    }
  }

  function getToggleGoogleStatus() {
    if (editingPost) {
      return editPostToggleGoogleStatus;
    } else {
      return toggleGoogleStatus;
    }
  }

  function getToggleInstagramStatus() {
    if (editingPost) {
      return editPostToggleInstagramStatus;
    } else {
      return toggleInstagramStatus;
    }
  }

  function renderCreateAPostModal(
    createPostModalOpen: boolean,
    setCreatePostModalOpen: (arg0: boolean) => void,
    editPostModalOpen?: boolean,
    setEditPostModalOpen?: (arg0: boolean) => void,
    postToEditIds?: any,
    isGroup: boolean = false
  ) {
    function resetCreatePostState(shouldResetAttachedPhotos: boolean = true) {
      setCreatePostModalOpen(false);
      setNewMessage("");
      if (shouldResetAttachedPhotos) {
        setAttachedPhotos([]);
      }
      setLoading(false);
      setDisabled(false);
      setPostProgress(0);
    }

    async function makePost() {
      const hasHitLimit = checkFeatureUsage(
        featureUsage,
        Features.SOCIAL_POST,
        ActionTiming.Before
      );
      if (hasHitLimit) {
        return;
      }

      let googleStatus = "";
      let facebookStatus = "";
      let instagramStatus = "";

      if (proHasChurned) {
        return setOpenChurnModal(true);
      }

      if (checkSocialContentError()) {
        return;
      }

      if (!attachedPhotos.length && !newMessage) {
        return;
      }

      setLoading(true);
      setDisabled(true);

      if (attachedPhotos.length > 0 || newMessage) {
        googleStatus = await postToGoogle(attachedPhotos);
        facebookStatus = await postToFacebook(attachedPhotos);
        instagramStatus = await postToInstagram(newMessage, attachedPhotos);
        setPostProgress(100);
      }

      updateFeatureUsage(slug, Features.SOCIAL_POST);

      pullPosts();

      setTimeout(() => {
        postToast(googleStatus, facebookStatus, instagramStatus);
        resetCreatePostState();
      }, 500);
    }

    async function editPost() {
      if (shouldOpenChurnModal()) return;

      if (proHasChurned) {
        return setOpenChurnModal(true);
      }

      if (checkSocialContentError()) {
        return;
      }

      setDisabled(true);
      setLoading(true);

      try {
        const fbResponse = await handleEditFacebookPost(
          postToEditIds,
          attachedPhotos
        );
        const gmbResponse = await handleEditGooglePost(
          postToEditIds,
          attachedPhotos
        );

        if (fbResponse === "success" && gmbResponse === "success") {
          editPostToast("success");
        } else if (fbResponse === "success" && !gmbResponse) {
          editPostToast("success");
        } else if (!fbResponse && gmbResponse === "success") {
          editPostToast("success");
        } else if (fbResponse === "error" || gmbResponse === "error") {
          editPostToast("failure");
        }
      } catch (error) {
        console.log("error:", error);
      }

      pullPosts();
      resetEditPostState();
    }

    async function editGroupPost(facebookGroupID: string = "") {
      const hasHitLimit = checkFeatureUsage(
        featureUsage,
        Features.SOCIAL_POST,
        ActionTiming.Before
      );
      if (hasHitLimit) {
        return;
      }

      if (proHasChurned) {
        return setOpenChurnModal(true);
      }
      if (checkSocialContentError(isGroup)) {
        return;
      }

      if (!attachedPhotos.length && !newMessage) {
        return;
      }

      setLoading(true);
      setDisabled(true);

      if (attachedPhotos.length > 0 || newMessage) {
        await editFacebookGroupPost(
          postToEditIds.groupPost,
          facebookGroupID,
          newMessage,
          attachedPhotos?.map((photo) => photo["data"])
        );
        setPostProgress(100);
      }

      updateFeatureUsage(slug, Features.SOCIAL_POST);

      pullPosts();
      resetEditPostState(false);
    }

    async function makeGroupPost(facebookGroupID: string = "") {
      const hasHitLimit = checkFeatureUsage(
        featureUsage,
        Features.SOCIAL_POST,
        ActionTiming.Before
      );
      if (hasHitLimit) {
        return;
      }

      if (proHasChurned) {
        return setOpenChurnModal(true);
      }

      if (checkSocialContentError(isGroup)) {
        return;
      }

      if (!attachedPhotos?.length && !newMessage) {
        return;
      }

      setLoading(true);
      setDisabled(true);

      if (attachedPhotos?.length > 0 || newMessage) {
        await createFacebookGroupPost(
          facebookGroupID,
          newMessage,
          attachedPhotos?.map((photo) => photo["data"])
        );
        setPostProgress(100);
        setAttachedPhotos(attachedPhotos);
      }

      updateFeatureUsage(slug, Features.SOCIAL_POST);
      pullPosts();

      setTimeout(() => {
        resetCreatePostState(false);
      }, 500);
    }

    function resetEditPostState(shouldResetAttachedPhotos: boolean = true) {
      if (shouldResetAttachedPhotos) {
        setAttachedPhotos([]);
      }
      setEditPostModalOpen && setEditPostModalOpen(false);
      setEditingPost(false);
      setOpenChurnModal(false);
      setDisabled(false);
      setLoading(false);
    }

    function openScheduleModal() {
      if (checkSocialContentError()) {
        return;
      }

      setCreatePostModalOpen(false);
      setSchedulePostModalOpen(true);
    }

    const coverPhotos: string[] = basicInfo?.hero_gallery_photos
      ? basicInfo?.hero_gallery_photos.map((element) => element)
      : [];

    return (
      <>
        {!createPostModalOpen && renderFeatureGateModal()}

        <CreateAPostModal
          createPostModalOpen={editPostModalOpen || createPostModalOpen}
          setCreatePostModalOpen={
            editingPost ? setEditPostModalOpen : setCreatePostModalOpen
          }
          newMessage={newMessage}
          setNewMessage={setNewMessage}
          handleChange={handleChange}
          attachedPhotos={attachedPhotos}
          setAttachedPhotos={setAttachedPhotos}
          renderSyncPills={renderSyncPills}
          renderSyncEditPostPills={renderSyncEditPostPills}
          attachingPhoto={attachingPhoto}
          openScheduleModal={openScheduleModal}
          loading={loading}
          makePost={
            isGroup
              ? editingPost
                ? editGroupPost
                : makeGroupPost
              : editingPost
              ? editPost
              : makePost
          }
          toggleFacebookStatus={getToggleFacebookStatus()}
          toggleGoogleStatus={getToggleGoogleStatus()}
          toggleInstagramStatus={getToggleInstagramStatus()}
          setEditPostToggleFacebookStatus={setEditPostToggleFacebookStatus}
          setEditPostToggleGoogleStatus={setEditPostToggleGoogleStatus}
          disabled={disabled}
          currentPlan={currentPlan}
          editingPost={editingPost}
          setEditingPost={setEditingPost}
          noScheduling={editingPost}
          handleGeneratePost={handleGeneratePost}
          generatingPost={generatingPost}
          postToEditIds={editingPost ? postToEditIds : []}
          setRewritePostErrorMessage={setRewritePostErrorMessage}
          rewritePostErrorMessage={rewritePostErrorMessage}
          showRewritePost={true}
          handleRewriteForMe={handleRewriteForMe}
          setGeneratingPost={setGeneratingPost}
          processImages={handleImagesSelected}
          slideUpOpen={slideUpOpen}
          setSlideUpOpen={setSlideUpOpen}
          isGroup={isGroup}
        >
          {renderChurnModal()}
          {renderSyncModals()}
          {renderFeatureGateModal()}

          <ActionModal
            open={replaceMessageModalOpen}
            setOpen={setReplaceMessageModalOpen}
            header="Generate post related to your digital content?"
            subheader="This will replace any existing post written."
            primaryButtonText="Generate New Post"
            primaryButtonIcon="sparkles"
            primaryButtonFunction={() => {
              handleGeneratePost();
              setReplaceMessageModalOpen(false);
            }}
            secondaryButtonText="Keep Existing"
            secondaryButtonFunction={() => setReplaceMessageModalOpen(false)}
            icon="refresh"
          />

          {loading && (
            <UploadProgressBar
              progress={postProgress}
              uploadingText={"Posting..."}
            />
          )}
        </CreateAPostModal>

        <SchedulePostModal
          isOpen={schedulePostModalOpen}
          setIsOpen={setSchedulePostModalOpen}
          message={newMessage}
          setMessage={setNewMessage}
          attachedPhotos={attachedPhotos}
          setAttachedPhotos={setAttachedPhotos}
          setCreatePostModalOpen={setCreatePostModalOpen}
          pullPosts={pullPosts}
          toggleFacebookStatus={toggleFacebookStatus}
          toggleGoogleStatus={toggleGoogleStatus}
          toggleInstagramStatus={toggleInstagramStatus}
          digitalAssetsCount={digitalAssetsCount}
        />
      </>
    );
  }

  return {
    renderCreateAPostModal,
    attachedPhotos,
    setAttachedPhotos,
    attachingPhoto,
    setAttachingPhoto,
    handleImagesSelected,
    handleGeneratePost,
    newMessage,
    setNewMessage,
    generatingPost,
    setGeneratingPost,
    newMediaLoading,
    setNewMediaLoading,
    setReplaceMessageModalOpen,
    editingPost,
    setEditingPost,
  } as const;
}
